/* eslint-disable no-unused-expressions */
import React from "react"
import { Helmet } from "react-helmet"

function isBrowser() {
  return typeof window !== "undefined"
}

function addGTM(w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l !== "dataLayer" ? "&l=" + l : ""
  j.async = true
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  f.parentNode.insertBefore(j, f)
}

function addTikTokAnalytics(w, d, t) {
  w.TiktokAnalyticsObject = t
  var ttq = (w[t] = w[t] || [])
  ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
  ]

  ttq.setAndDefer = function (t, e) {
    t[e] = function () {
      t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
    }
  }

  for (let i = 0; i < ttq.methods.length; i++) {
    ttq.setAndDefer(ttq, ttq.methods[i])
    ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n])
      return e
    }
  }

  ttq.load = function (e, n) {
    var i = "https://analytics.tiktok.com/i18n/pixel/events.js"
    ttq._i = ttq._i || {}
    ttq._i[e] = []
    ttq._i[e]._u = i
    ttq._t = ttq._t || {}
    ttq._t[e] = +new Date()
    ttq._o = ttq._o || {}
    ttq._o[e] = n || {}
    var o = document.createElement("script")
    o.type = "text/javascript"
    o.async = !0
    o.src = i + "?sdkid=" + e + "&lib=" + t
    var a = document.getElementsByTagName("script")[0]
    a.parentNode.insertBefore(o, a)
  }
  ttq.load("CEKLEH3C77U8PGLVV5HG")
  ttq.page()
}

function addTikTokAnalytics2(w, d, t) {
  w.TiktokAnalyticsObject = t
  var ttq = (w[t] = w[t] || [])
  ;(ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
  ]),
    (ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
      }
    })
  for (var i = 0; i < ttq.methods.length; i++)
    ttq.setAndDefer(ttq, ttq.methods[i])
  ;(ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
      ttq.setAndDefer(e, ttq.methods[n])
    return e
  }),
    (ttq.load = function (e, n) {
      var i = "https://analytics.tiktok.com/i18n/pixel/events.js"
      ;(ttq._i = ttq._i || {}),
        (ttq._i[e] = []),
        (ttq._i[e]._u = i),
        (ttq._t = ttq._t || {}),
        (ttq._t[e] = +new Date()),
        (ttq._o = ttq._o || {}),
        (ttq._o[e] = n || {})
      n = document.createElement("script")
      ;(n.type = "text/javascript"),
        (n.async = !0),
        (n.src = i + "?sdkid=" + e + "&lib=" + t)
      e = document.getElementsByTagName("script")[0]
      e.parentNode.insertBefore(n, e)
    })

  ttq.load("CF50VTBC77U9H3ERIEH0")
  ttq.page()
}

function addAllTikTokAnalytics(w, d, t,IdArray) {
  w.TiktokAnalyticsObject = t
  var ttq = (w[t] = w[t] || [])
  ;(ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
  ]),
    (ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
      }
    })
  for (var i = 0; i < ttq.methods.length; i++)
    ttq.setAndDefer(ttq, ttq.methods[i])
  ;(ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
      ttq.setAndDefer(e, ttq.methods[n])
    return e
  }),
    (ttq.load = function (e, n) {
      var i = "https://analytics.tiktok.com/i18n/pixel/events.js"
      ;(ttq._i = ttq._i || {}),
        (ttq._i[e] = []),
        (ttq._i[e]._u = i),
        (ttq._t = ttq._t || {}),
        (ttq._t[e] = +new Date()),
        (ttq._o = ttq._o || {}),
        (ttq._o[e] = n || {})
      n = document.createElement("script")
      ;(n.type = "text/javascript"),
        (n.async = !0),
        (n.src = i + "?sdkid=" + e + "&lib=" + t)
      e = document.getElementsByTagName("script")[0]
      e.parentNode.insertBefore(n, e)
    })
    for(let i =0; i<IdArray.length;i++){
      ttq.load(IdArray[i])
      ttq.page()
    }

}
function CuboTT(w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CG81L8JC77U7T197KPV0');
  ttq.page();
}

function FBCode(f,b,e,v,n,t,s){
 if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s) 
}
function FBStep2(fn){
  fn('init', '142300305429136');
  fn('track', 'PageView');
}

export function addAnalysisTool() {
  if (isBrowser()) {
    addGTM(window, document, "script", "dataLayer", "GTM-T4WTN9R")
    // addTikTokAnalytics(window, document, "ttq")
    // addTikTokAnalytics2(window, document, "ttq")
    // CuboTT(window, document, "ttq")
    // FBCode(window, document,'script',
    // 'https://connect.facebook.net/en_US/fbevents.js')
    // FBStep2(window._fbq)
  }
}

function ADTikTok1(w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CF53B1JC77U2ISB9GES0');
  ttq.page();
}
function ADTikTok2(w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CF6HM2JC77UEUGLDJLFG');
  ttq.page();

}function ADTikTok3(w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CF53F1BC77U9H3ERIH3G');
  ttq.page();
}



export const AllAnalysisTool = () => {
 
  if (isBrowser()) {
    // addAllTikTokAnalytics(window, document, "ttq", [
    //   "CF6HM2JC77UEUGLDJLFG",
    //   "CF53F1BC77U9H3ERIH3G",
    //   "CF53B1JC77U2ISB9GES0",
    // ])
    ADTikTok1(window, document, 'ttq')
    ADTikTok2(window, document, 'ttq')
    ADTikTok3(window, document, 'ttq')
  }

  return(
    <Helmet htmlAttributes={{ lang: "en" }} title={"Cubo"}>
    {/* <script type="text/javascript" async="async" src="https://analytics.tiktok.com/i18n/pixel/static/main.MWE2YWY2YTgzMQ.js" data-id="CF6HM2JC77UEUGLDJLFG" />
    <script type="text/javascript" async="async" src="https://analytics.tiktok.com/i18n/pixel/static/main.MWE2YWY2YTgzMQ.js" data-id="CF53F1BC77U9H3ERIH3G" />
    <script type="text/javascript" async="async" src="https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=CF6HM2JC77UEUGLDJLFG&lib=ttq" />
    <script type="text/javascript" async="async" src="https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=CF53F1BC77U9H3ERIH3G&lib=ttq" /> */}
    {/* <script type="text/javascript" async="defer" src="https://s9.cnzz.com/z_stat.php?id=1281210407&web_id=1281210407"  /> */}
    </Helmet>
  )
}
 

function landingFour(w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CFMUI33C77U208RTSGT0');
  ttq.page();
}
export const LandingFourPixel=()=>{
  if (isBrowser()) {
    landingFour(window, document, 'ttq')
  }
  return(
    <Helmet htmlAttributes={{ lang: "en" }} title={"Cubo"}>
    </Helmet>
  )
}

export const NoIndexMeta=()=>{
  return(
    <Helmet htmlAttributes={{ lang: "en" }} title={"Cubo"}>
      <meta name="robots" content="noindex" /> 
    </Helmet>
  )
  
}
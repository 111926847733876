import ApiService from '../utils/request/api-service';
// (1-mac客户端注册 2-win客户端注册 3-web注册 4-安卓注册 5-ios注册)
export function getDeviceCode() {
  if (typeof window !== "undefined") {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      return 5
    }
    if (/(Android)/i.test(navigator.userAgent)) {
      return 4
    }
    return 3
  }
  return 3
}

const service = new ApiService();

// 创建用户并调整付款页
export const createStaffToPay = (param) => service
  .post('/funworking/v1/pay/createStaffToPay', param);

export const sendPaySuccessEmail = (param) => service
  .post('/funworking/v3/index/sendPaySuccessEmail', param);

export const getWindowsLocation = () => {
  if (typeof Window !== 'undefined') {
    return window.location.href
  }
  return ''
}